import "./App.css";
import Welcome from "./welcome/Welcome";

function App() {
    return (
        <div className="app-container">
            <Welcome />
        </div>
    );
}

export default App;
